$('document').ready(function(){
	//MOBILE NAVIGATION
	$('.j-nav-icon').on('click', function(){
		$(this).toggleClass('open');
		$(this).prev().toggleClass('open');
		$('.logo-link').toggleClass('open');
		$('.j-h-topper').toggleClass('open');
	});

	//STICKY JS
	$(".j-header").sticky({topSpacing:0});

    $(window).on('resize', function() {
        $("#sticky-wrapper").attr('style', 'height:auto');
    });

    const setSubmenusPosition = () => {
        const subMenus = Array.from(document.querySelectorAll('.sub-menu'));

        subMenus.forEach(el => {
            const menu_with_children = el.parentElement;
            const main_menu = menu_with_children.parentElement;
            const submenu_width = el.offsetWidth;

            const is_far_right = (menu_with_children.offsetLeft + submenu_width) > main_menu.offsetWidth;

            if (is_far_right) {
                el.classList.add('sub-menu-right');
            }
            else {
                el.classList.remove('sub-menu-right');
            }
        });
    }

    setSubmenusPosition();

    window.addEventListener('resize', () => {
        setSubmenusPosition();
    });

	//ACCORDION
	$('.acrd-h').on('click', function(){
		var header = $(this);
		var icon = $(this).find('.acrd-i');
		var body = $(this).next();
		var container = $(this).parent();

		body.slideToggle("fast");
		icon.toggleClass('fa-plus');
		icon.toggleClass('fa-minus');
		container.toggleClass('open');
	});
	$('#first-acrd .acrd-h').trigger('click'); //Open the first accordion on load

	//MISC 
	//if browser is safari add a class to the body, this is used for a flexbox fix for sarfari only on common.scss line 410
	var isSafari = !!navigator.userAgent.match(/safari/i) && !navigator.userAgent.match(/chrome/i) && typeof document.body.style.webkitFilter !== "undefined" && !window.chrome;
	if (isSafari) { $('body').addClass('safari-browser-true'); }




    // wraps scrollable tables on medium and smaller viewports
        function scrollWrap() {
            $('table, .table-flex').wrap('<div class="scroll-wrapper"></div>');
            $('.scroll-wrapper').before( '<div class="scroll-header">Swipe to view more content</div>' );
        }
        scrollWrap();

    // show or hide table scroll header based on if overflowing its parent
        function handleScrollResize() {
            $('table, .table-flex').each(function(index) {
                var table = $(this);
                var tableWidth = table.outerWidth();
                var parentWidth = table.parent().outerWidth();
                var scrollHeader = table.parent().prevAll('.scroll-header:first');

                if (tableWidth > parentWidth) {
                    // element has overflow
                    scrollHeader.show();
                    table.parent().addClass('state-overflow');
                } else {
                    // element doesn't overflow
                    scrollHeader.hide();
                    table.parent().removeClass('state-overflow');
                }
            })
        }
        $(window).on("resize",function () {
            handleScrollResize();
        });
        $(document).ready( function () {
            handleScrollResize();
        });
});